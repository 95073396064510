import React from "react";
import { useEffect } from "react";
import Basemap from "./Basemap.jsx";
import Card from "./Card.jsx";
import Homepage from "./Homepage.jsx";
import BlockedHomepage from "./BlockedHomepage.jsx";

export default function App() {
    const deviceWidth = window.innerWidth;
    return (
        <>
            {deviceWidth < 500 ? (
                <>
                    <Basemap />
                    <Card />
                    <Homepage />
                </>
            ) : (
                <BlockedHomepage />
            )}
        </>
    );
}
