import { Image, Button, Header, Grid } from "semantic-ui-react";
import { useStore } from "./store/useStore";

export default function BlockedHomepage() {
    const margin = 20;

    const qrs = {
        titles: ["Water", "Fire", "Earth", "Air"],
        codes: [
            "./QR/water.png",
            "./QR/fire.png",
            "./QR/earth.png",
            "./QR/air.png",
        ],
    };

    const contStyle = {
        zIndex: 1001,
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        pointerEvents: "none",
        textAlign: "middle",
        backgroundColor: "black",
    };

    const topRight = {
        position: "absolute",
        right: 0,
        margin: margin + "px",
        pointerEvents: "auto",
        filter: "invert(100%)",
    };

    const topLeft = {
        position: "absolute",
        left: 0,
        top: 0,
        margin: margin + "px",
        pointerEvents: "auto",
        filter: "invert(100%)",
    };

    const contTextStyle = {
        padding: margin / 2,
        width: "50%",
        height: "100%",
        top: 0,
        left: 0,
        overflow: "scroll",
        paddingTop: "10%",
        pointerEvents: "auto",
        textAlign: "middle",
        overflowX: "hidden",
        paddingLeft: margin,
    };

    const paragraph = {
        textIndent: "2em",
        color: "white",
    };

    const chapter = {
        fontSize: "3em",
        fontWeight: 400,
        color: "white",
    };

    const botmRight = {
        position: "absolute",
        right: 0,
        bottom: 0,
        marginBottom: margin + "px",
        marginRight: margin * 2 + "px",
        pointerEvents: "auto",
        width: "60%",
    };

    const linkStyle = {
        color: "white",
        textDecoration: "underline",
    };

    return (
        <>
            <div style={contStyle}>
                <Image
                    width={"10%"}
                    style={topLeft}
                    src="./Logos/Connected Ecologies Logo2.png"
                />

                <Image
                    width={"10%"}
                    style={topRight}
                    src="./Logos/Model Logo.png"
                />
                <div style={contTextStyle}>
                    <Header
                        as="h1"
                        style={chapter}
                    >
                        Welcome
                    </Header>
                    <p style={paragraph}>
                        Welcome to Connected Ecologies! Our digital installation
                        for the park La Gran Clariana during the
                        <a
                            href="https://www.model.barcelona/edicio2023/en"
                            target="_blank"
                            style={linkStyle}
                        >
                            '23 Model Festival of Architecture
                        </a>
                        . As we build the future of our cities, the challenges
                        we face continue to evolve in their complexity, nature,
                        scale and impact. More people will be living in cities
                        or urbanised areas and how these will be spatially,
                        materially, environmentally, socially, is yet to be
                        determined...
                        <br />
                        <br />
                        How can we affect these potential futures? <br />
                        What if we could influence them? <br />
                        And who would be there to tell us about how things
                        changed from today until then?
                        <br />
                        <br />
                        Scan one of the four QR codes with your mobile and start
                        your journey!
                    </p>
                </div>

                <Grid
                    style={botmRight}
                    columns={4}
                    textAlign="middle"
                >
                    {qrs.titles.map((titles, index) => (
                        <Grid.Column textAlign="middle">
                            <Image
                                src={qrs.codes[index]}
                                style={{ filter: "invert(100%)" }}
                            />
                            <Header
                                textAlign="centered"
                                as="h4"
                                style={{ color: "white", fontWeight: 400 }}
                            >
                                {titles}
                            </Header>
                        </Grid.Column>
                    ))}
                </Grid>
            </div>
        </>
    );

    return <></>;
}
